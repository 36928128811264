import React from 'react'
import {BsFacebook, BsLinkedin, BsTwitter, BsInstagram} from 'react-icons/bs'

const Footer = () => {
  return (
    <div className='footer pb-10 pt-5 w-full block text-center md:flex md:justify-between px-2 md:px-20'>
        <div className='flex justify-center pb-4 md:pb-0'>
            <BsFacebook className='footer-icon'/>
            <BsLinkedin className='footer-icon'/>
            <BsTwitter className='footer-icon'/>
            <BsInstagram className='footer-icon'/>
        </div>
        <div className=''>
            <h6>Terms & conditon Privacy Policy</h6>
        </div>
    </div>
  )
}

export default Footer