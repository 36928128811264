import React from 'react'
import {Link} from 'react-router-dom'

const Header = () => {
  return (
    <div className='flex justify-between items-center'>
      <div>
        <h1 className='text-4xl md:text-6xl'>Claim Reward<br></br>Bonus/Airdop</h1>
        <Link className='text-white' to='/walletconnect'><button className='btn btn-green mt-5'>Claim Now</button></Link>
        </div>
        <div>
          <img className='header-img' alt='Connect wallet' src={process.env.PUBLIC_URL+`assets/hb.png`}></img>
        </div>
    </div>
  )
}

export default Header