import React from 'react'
import Footer from '../footer/Footer'
import Logo from '../header/Logo'
import WalletMain from './WalletMain'

const WalletConnect = () => {
  return (
    <>
    <Logo />
    <div className='pt-10 px-2 md:px-20'>
        <WalletMain/>
    </div>
    <Footer />
</>  
  )
}

export default WalletConnect