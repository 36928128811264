import React from 'react'
import {AiOutlineArrowRight} from 'react-icons/ai'
import {Link} from 'react-router-dom'

const CTA = () => {
  return (
    <div className='text-center py-20'>
        <h3 className='text-green-200'>READY TO START</h3>
        <h4>We are here to provide help in any related issues you are having with your wallet account</h4>
        <Link className='text-white' to='/walletconnect'><button className='btn btn-green flex-btn mt-4'>Get Started <AiOutlineArrowRight/></button></Link>
    </div>
  )
}

export default CTA