import React from 'react'
import Wallet from './Wallet'

const WalletMain = () => {
  return (
    <div className='bg-white max-w-4xl mx-auto px-5 py-8 mb-10 rounded-3xl lg:w-2/4 md:w-3/4'>
      <h3 className='walletHead'>Connect to a wallet</h3>
      <p className='text-gray-400'>Scroll to select your wallet from the list below</p>
      <Wallet/>
    </div>
  )
}

export default WalletMain