import React from 'react'
import { GiStrikingDiamonds } from 'react-icons/gi'
import './Logo.css'
import { useState } from 'react'

const Logo = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    return (
        <nav className="navigation px-2 md:px-20 pt-4">
            <a className='text-white' href='/'>
                <div className='flex'>
                    <GiStrikingDiamonds className='logo' color='#31b496' />
                    <h4>Crypto Connectic</h4>
                </div>
            </a>
            <button className="hamburger" onClick={() => {
                setIsNavExpanded(!isNavExpanded);
            }}>
                {/* icon from heroicons.com */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                >
                    <path
                        fillRule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            <div
                className={
                    isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                }>
                <ul>
                    <li><a className='text-white' href="/">Bitcoin</a></li>
                    <li><a className='text-white' href="/">Ethereum</a></li>
                    <li><a className='text-white' href="/">Tether</a></li>
                    <li><a className='text-white' href="/">Solana</a></li>
                    <li><a className='text-white' href="/">Litecoin</a></li>
                    <li><a className='text-white' href="/">Polkadot</a></li>
                    <li><a className='text-white' href="/">Support</a></li>
                </ul>
            </div>
        </nav>
    )
}

export default Logo