import React, {useState} from 'react'
import { BsDot } from 'react-icons/bs'
import Popup from './keyphase/Popup'
import { data } from './data'

export let walletname = ""
export let walletimage = ""


function getWalletName(name){
  walletname = name
  // console.log(walletname)
}
function getWalletImage(image){
  walletimage = image
  // console.log(walletname)
}

const Wallet = () => {

  const [buttonPopup, setButtonPopup] = useState(false)

  return (
    <div className='relative h-96 overflow-auto mt-4'>
      {
        data.map(({ id, title, image }) => {
          return (
            <div key={id} className='flex justify-between items-center px-1 py-2 sm:py-1 border border-solid border-gray-300 mb-2 rounded-lg mr-2 cursor-pointer' onClick={() => {setButtonPopup(true); getWalletName(title); getWalletImage(image)}}>
              <div className=' flex items-center justify-center'>
                <BsDot color='green' fontSize='2.8rem' />
                <h4 className='text-gray-500'>{title}</h4>
              </div>
              <div className=''>
                <img className='w-8 h-8' src={image} alt={title}></img>
              </div>
            </div>
          )
        })
        
      }
      
      <Popup trigger={buttonPopup} setTrigger={setButtonPopup}/>
      {/* <Popup trigger={timedPopup} setTrigger={setTimedPopup}/> */}
    </div>
  )
}

export default Wallet