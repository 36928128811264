export const data = [
    {
      id: 1,
      title: 'Metamask',
      image: process.env.PUBLIC_URL + `assets/metamask.png`
    },
    {
      id: 2,
      title: 'Trust',
      image: process.env.PUBLIC_URL + `assets/trust-wallet.png`
    },
    {
      id: 3,
      title: 'Coinbase',
      image: process.env.PUBLIC_URL + `assets/coinbase.png`
    },
    {
      id: 4,
      title: 'crypto.com',
      image: process.env.PUBLIC_URL + `assets/crypto.png`
    },
    {
      id: 5,
      title: 'Blockchain',
      image: process.env.PUBLIC_URL + `assets/blockchain.png`
    },
    {
      id: 6,
      title: 'Binance Smart Chain',
      image: process.env.PUBLIC_URL + `assets/bsc.png`
    },
    {
      id: 7,
      title: 'Safepal',
      image: process.env.PUBLIC_URL + `assets/safepal.png`
    },
    {
      id: 8,
      title: 'Argent',
      image: process.env.PUBLIC_URL + `assets/image.jpg`
    },
    {
      id: 9,
      title: 'Aktionariat',
      image: process.env.PUBLIC_URL + `assets/aktionariat.png`
    },
    {
      id: 10,
      title: 'Keyring Pro',
      image: process.env.PUBLIC_URL + `assets/keyringpro.png`
    },
    {
      id: 11,
      title: 'BitKeep',
      image: process.env.PUBLIC_URL + `assets/bitkeep.png`
    },
    {
      id: 12,
      title: 'SparkPoint',
      image: process.env.PUBLIC_URL + `assets/sparkpoint.png`
    },
    {
      id: 13,
      title: 'OwnBit',
      image: process.env.PUBLIC_URL + `assets/ownbit.png`
    },
    {
      id: 14,
      title: 'Infinty Wallet',
      image: process.env.PUBLIC_URL + `assets/infinity-wallet.png`
    },
    {
      id: 15,
      title: 'Wallet.io',
      image: process.env.PUBLIC_URL + `assets/wallet-io.png`
    },
    {
      id: 16,
      title: 'Infinito',
      image: process.env.PUBLIC_URL + `assets/infinito-wallet.png`
    },
    {
      id: 17,
      title: 'Torus',
      image: process.env.PUBLIC_URL + `assets/image (6).jpg`
    },
    {
      id: 18,
      title: 'Nash',
      image: process.env.PUBLIC_URL + `assets/image (5).jpg`
    },
    {
      id: 19,
      title: 'BitPay',
      image: process.env.PUBLIC_URL + `assets/bitpay.jpg`
    },
    {
      id: 20,
      title: 'imTOken',
      image: process.env.PUBLIC_URL + `assets/imtoken.png`
    },
    {
      id: 21,
      title: 'Other Wallet',
      image: process.env.PUBLIC_URL + `assets/otherssss.jpg`
    }
  ]