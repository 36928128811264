import React from 'react'
import CTA from './components/cta/CTA'
import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import Logo from './components/header/Logo'
import Main from './components/main/Main'




const App = () => {
    return (

        <>
            <Logo />
            <div className='pt-10 px-2 md:px-20'>
                <Header />
                <Main />
                <CTA />
            </div>
            <Footer />
        </>
    )
}

export default App