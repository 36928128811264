import React from 'react'
import Cards from './Cards'

const Main = () => {
  return (
    <div className='mt-16'>
        <h2 className='text-3xl'>Please select Issue Category</h2>
        <h4 className='sub-head'>which of this is related to your issue?</h4>

        <Cards/>
    </div>
  )
}

export default Main