import React from "react";
import ReactDOM from "react-dom";
import App from "./App"
import "./index.css"

import { BrowserRouter, Routes, Route } from "react-router-dom";
import WalletConnect from './components/wallet-connect/WalletConnect'




const root = ReactDOM.createRoot(
    document.getElementById("root")
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/walletconnect" element={<WalletConnect />} />
            </Routes>
            {/* <App /> */}
        </BrowserRouter>
    </React.StrictMode>
);